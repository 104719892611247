import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-554d37d6"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "body"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_nav_bar = _resolveComponent("van-nav-bar");
  const _component_van_card = _resolveComponent("van-card");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_van_nav_bar, {
    title: "文章列表",
    style: {}
  }), _createElementVNode("div", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.kplist, kp => {
    return _openBlock(), _createBlock(_component_van_card, {
      key: kp.id,
      desc: kp.author,
      class: "rand border",
      title: kp.title,
      thumb: kp.imageInput,
      onClick: $event => $options.onSelect(kp)
    }, null, 8, ["desc", "title", "thumb", "onClick"]);
  }), 128))])]);
}